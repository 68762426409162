var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutSuperadmin',{attrs:{"sidebarActiveItem":{
    value: 'order',
    children: [
      _vm.status === _vm.STATUS.QUOTE_APPROVED.value
        ? 'approved'
        : _vm.status === _vm.STATUS.COMPLETED.value
        ? 'completed'
        : 'request' ],
  }},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.status === _vm.STATUS.QUOTE_APPROVED.value)?_c('span',[_vm._v(" Approved Orders ")]):(_vm.status === _vm.STATUS.COMPLETED.value)?_c('span',[_vm._v(" Completed Orders ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"order-request p-2 p-md-5 h-100 bg-gray-light-4"},[(!_vm.orders.current_page)?_c('AppLoading',{attrs:{"fillSpace":""}}):_c('BuyerOrderTable',{attrs:{"status":_vm.status,"items":_vm.orders,"sort":_vm.sorts},on:{"sort":_vm.sortBy,"pageChange":_vm.pageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }